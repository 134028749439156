<template>
  <div>
    <v-img
        gradient="to top, rgba(255,255,255,1), rgba(25, 174, 197,.6)"
    >
      <v-container class="custom-container">

        <v-fade-transition>
          <v-row class="d-flex align-stretch" v-if="!cargando_informaciones && mensajePrincipal !== ''">

            <v-col
                cols="12"
                class="pt-1"
            >
              <v-card
                  class="anuncio"
                  style="height: 100%"
                  color="#fff6c4"
                  flat
              >

                <v-card-text class="py-4 px-6">
                  <div v-html="mensajePrincipal">

                  </div>
                </v-card-text>


              </v-card>
            </v-col>
          </v-row>
        </v-fade-transition>
        <v-row>
          <v-col cols="12" class="pt-1">
            <v-card to="/videotutoriales" style="cursor: pointer">
              <v-img
                  v-if="$vuetify.breakpoint.xsOnly"
                  :src="require('@/assets/img/banner-ayuda/banner-425x160.png')"
                  height="160px"
              />
              <v-img
                  v-else-if="$vuetify.breakpoint.mdAndDown"
                  :src="require('@/assets/img/banner-ayuda/banner-768x240.png')"
                  height="240px"
              />
              <v-img
                  v-else-if="$vuetify.breakpoint.lgAndUp"
                  :src="require('@/assets/img/banner-ayuda/banner-3840x470.png')"
                  height="200px"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12" class="pt-1">
            <v-card color="primary">
              <v-img
                  eager
                  max-height="200"
                  :src="require('@/assets/img/fondo-index.jpg')"
                  gradient="to top, rgba(25, 174, 197,.2), rgba(17,129,146,.8)"
              >
                <div class="d-flex flex-column justify-center align-center">
                  <v-img
                      class="px-5 mt-3 mb-4"
                      alt="CBRLS Logo"
                      contain
                      :src="require('@/assets/img/cbrls-logo-index.svg')"
                      eager
                      max-height="70"
                  />
                  <!--
                  <div>
                     <h1
                        :class="$vuetify.breakpoint.mdAndUp ? 'titulo-lg':'titulo-sm'"
                        class="titulo white--text text-center mt-5"
                    >Conservador de Bienes Raíces La Serena</h1>
                    <h4
                        :class="$vuetify.breakpoint.mdAndUp ? 'subtitulo-lg':'subtitulo-sm'"
                        class="white--text text-center mt-0"
                    >Portal de Transacciones</h4>
                  </div>
                  -->
                  <HomePageConsultador/>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <!--
        <v-row class="mb-1">
          <v-col sm="12">
            <v-card>
              <v-img
                id="#banner-index"
                aspect-ratio="7.3"
                :src="require('@/assets/img/banner-web-2-txt.jpg')"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
        -->
        <v-row>
          <v-col class="pb-2 pt-2">
            <p id="titulo-introductorio" class="text-h5 mb-0 text-center font-weight-black">
              SOLICITE DOCUMENTOS DE LOS SIGUIENTES REGISTROS
            </p>
          </v-col>
        </v-row>
        <RegistrosPrincipales/>

        <!-- <TarjetasOpciones/> -->

        <v-row class="d-flex align-center py-6 py-md-0">
          <v-col
              lg="5"
              xs="12"
          >
            <div>
              <v-img class="oscurecer" eager :src="require(`@/assets/img/banner-bottom.jpg`)"/>
            </div>

          </v-col>
          <v-col
              lg="7"
              xs="12"
          >
            <v-carousel
                class="carousel-small carousel-custom"
                :show-arrows="false"
                height="auto"
                cycle
                continuous
            >
              <v-carousel-item eager>
                <v-img eager :src="require(`@/assets/img/slider-home/1.png`)"></v-img>
              </v-carousel-item>

              <v-carousel-item eager>
                <router-link to="/indices">
                  <v-img class="oscurecer" eager :src="require(`@/assets/img/slider-home/2.png`)"></v-img>
                </router-link>
              </v-carousel-item>

            </v-carousel>
          </v-col>
        </v-row>
      </v-container>

    </v-img>
    <modal-ver-publicacion-inicial :time="10" :ver-dialog="verModalMensajeInicio"
                                   @ocultarDialog="verModalMensajeInicio = false"></modal-ver-publicacion-inicial>

  </div>


</template>

<script>
import "particles.js";
import RegistrosPrincipales from "@/components/Tramites/RegistrosPrincipales";

import HomePageConsultador from "@/components/HomePage/HomePageConsultador";
import infoEntity from "@/model/informaciones/info.entity";
import ModalVerPublicacionInicial from "@/views/index/index/modalVerPublicacionInicial";
// import usuarioEntity from "@/model/usuario/usuario.entity";
// import {
//   ULTIMO_ESTADO_MENSAJE
// } from "@/model/usuario/usecases/get-estado-visualizacion-informacion-inicio";

export default {
  name: "HomeScreen",
  components: {
    ModalVerPublicacionInicial,
    HomePageConsultador,
    RegistrosPrincipales,

  },
  data: () => ({
    cargando_informaciones: false,
    mensajePrincipal: "",
    verModalMensajeInicio: false
  }),
  created: function () {
    this.$vuetify.goTo(0);
    this.getInformacionInicio();

  },
  mounted() {
    // if(usuarioEntity.getEstadoVisualizacionInformacionInicio() != ULTIMO_ESTADO_MENSAJE){
    /*
    setTimeout(() => {
      this.getEstadoVisualizacionModalInicial();
    }, 1000)
    */

    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.getEstadoVisualizacionModalInicial();
      }
    }

    // usuarioEntity.setEstadoVisualizacionInformacionInicio(ULTIMO_ESTADO_MENSAJE);
    // }


  },
  methods: {

    getInformacionInicio() {
      this.cargando_informaciones = true;
      infoEntity.getMensajeInicio().then(res => {
        this.mensajePrincipal = res;
        this.cargando_informaciones = false;
      })
    },

    getEstadoVisualizacionModalInicial() {
      console.log("se llama al metodo para buscar el modal")
      this.verModalMensajeInicio = true;
    }
  }

};
</script>

<style lang="scss" scoped>
.normal-line-height {
  line-height: 1;
}

.shadow {
  text-shadow: 2px 2px 8px #0000009e;
}

.titulo {
  line-height: 24px;
}

.titulo-lg {
  font-size: 300%;
}

.subtitulo-lg {
  font-size: 170%;
}

.titulo-sm {
  font-size: 150%;
}

.subtitulo-sm {
  font-size: 100%;
}

.markdown-body a {
  background-color: white;
}

.markdown-body p {
  padding-bottom: 0 !important;
}

#titulo-introductorio {
  color: #088397;
  letter-spacing: 2px;
}

.anuncio::v-deep p {
  line-height: 1.3;
}

.oscurecer:hover {
  transition-duration: 500ms;
  transition-property: filter;
  filter: brightness(0.8)
}

.carousel-custom::v-deep .v-carousel__controls {
  height: 20px;
  background: rgba(0, 0, 0, 0.15)
}

.carousel-custom::v-deep button .mdi-circle {
  font-size: 10px !important;
}

.carousel-custom::v-deep .v-btn--icon.v-size--small {
  height: 15px;
  width: 15px;
}
</style>
