<template>
  <div>
    <v-dialog max-width="700" v-model="ver">
      <v-card>
        <v-card-text class="pa-1">
          <router-link to="/inscripciones/inscripcion-propiedad">
            <v-img :src="require('@/assets/img/popup-inicio/inicio.jpg')"></v-img>
          </router-link>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>

</template>

<script>
export default {
  name: "modalVerPublicacionInicial",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false,
      carga: 0,
      progreso: 0
    }
  },
  mounted() {

  },
  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }
      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>