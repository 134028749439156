<template>
  <v-card
      id="tab-consultador"
      :class="$vuetify.breakpoint.smAndUp ? 'tab-large':'tab-small'"
      class="mb-5"
  >
    <v-tabs
        fixed-tabs
        centered
        dark
        :show-arrows="false"
    >
      <v-tab>Estado Carátula</v-tab>
      <v-tab v-if="$vuetify.breakpoint.smAndUp">Verificar doc. electrónico</v-tab>
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab-item>
        <v-card-text
            class="py-2 px-2"
        >
          <v-form
              class="d-flex align-center justify-center"
              @submit.prevent="handleCaratula()"
          >
            <v-text-field
                v-model="numeroCaratula"
                solo
                dense
                :autofocus="!$vuetify.breakpoint.mobile"
                placeholder="Ej: 800000"
                hide-details
            >
              <template v-slot:append>
                <v-btn
                    type="submit"
                    color="primary"
                    small
                    class="pa-0"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text
            class="py-2 px-2"
        >
          <v-form
              class="d-flex align-center justify-center"
              @submit.prevent="handleDocumento()"
          >
            <v-text-field
                v-model="numeroDocumento"
                solo
                dense
                :autofocus="!$vuetify.breakpoint.mobile"
                placeholder="Ej: 13569845"
                hide-details
            >
              <template v-slot:append>
                <v-btn
                    type="submit"
                    color="primary"
                    small
                    class="pa-0"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "HomePageConsultador",
  data() {
    return {
      numeroCaratula: null,
      numeroDocumento: null,
    }
  },
  methods: {
    handleCaratula() {
      this.$router.push({ name: 'ConsultasCaratulas', params: { ncaratula: this.numeroCaratula } });
    },
    handleDocumento() {
      this.$router.push({ name: 'ConsultasVerificarDocumento', params: { ndocumento: this.numeroDocumento } });
    }
  },
}
</script>

<style scoped>
#tab-consultador {
  background-color: rgba(0, 0, 0, 0.2);
}

#tab-consultador.tab-small {
  width: 270px;
}

#tab-consultador.tab-large {
  width: 400px;
}

#tab-consultador::v-deep .v-tabs-bar {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
}

#tab-consultador::v-deep .v-tabs-items {
  background-color: rgba(0, 0, 0, 0.2);
}

#tab-consultador .v-tab {
  font-size: 10px;
  font-weight: normal;
}

#tab-consultador::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>